export const sysVersion = "1.1.0";
export let apiUrl = "";
export const imageUrl = "https://mybee-server.onrender.com";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  apiUrl = "http://localhost:3000";
  // dev code
} else {
  apiUrl = "https://mybee-server.onrender.com";
  // production code
}
