import {
  Box,
  Divider,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { PLUGIN_NAME } from "../../config/const";
import { apiUrl } from "../../config/settings";
import NullCheck from "../../components/NullCheck";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  ArrowBackIos,
  ArrowForwardIos,
  FormatQuote,
} from "@mui/icons-material";
import CustomIconButton from "../../components/CustomIconButton";
import { useTranslation } from "react-i18next";
import { lineText } from "../../config/functions";
import CustomDialog from "../../components/CustomDialog";

const Recommend = ({ bgColor }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const [list, setList] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(1);
  const [aminDirection, setAminDirection] = useState("right");
  const [aminStart, setAminStart] = useState(true);
  const [recommendDialog, setRecommendDialog] = useState(null);

  const minSwipeDistance = 50;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    if (plugins?.includes(PLUGIN_NAME.RECOMMEND)) {
      axios.get(`${apiUrl}/recommend`).then((response) => {
        setList(response?.data?.data);
      });
    }
  }, []);

  useEffect(() => {
    if (list.length > 1) {
      const timer = setInterval(() => {
        forward();
        clearTimeout(timer);
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [list, start]);

  const forward = () => {
    setAminDirection("left");
    const timer = setTimeout(() => {
      setStart((data) => (end < list?.length ? data + 1 : 0));
      setEnd((data) => (end < list?.length ? data + 1 : 1));
      startAnim(1);
      clearTimeout(timer);
    }, 5);
  };

  const back = () => {
    setAminDirection("right");
    const timer = setTimeout(() => {
      setStart((data) => (start > 0 ? data - 1 : list?.length - 1));
      setEnd((data) => (start > 0 ? data - 1 : list?.length));
      startAnim(2);
      clearTimeout(timer);
    }, 5);
  };

  const startAnim = (type) => {
    setAminStart(false);
    const timer = setTimeout(() => {
      if (type === 1) setAminDirection("right");
      else setAminDirection("left");

      setAminStart(true);
      clearTimeout(timer);
    }, 500);
  };

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      isLeftSwipe ? back() : forward();
    }
  };

  if (!plugins?.includes(PLUGIN_NAME.RECOMMEND)) return;

  return (
    <Stack alignItems="center" padding={1} bgcolor={bgColor ?? "primary.main"}>
      <Typography color="secondary" variant="h5">
        {t("RECOMMEND")}
      </Typography>
      <Stack
        overflow="hidden"
        padding={2}
        width="100%"
        direction="row"
        alignItems="center"
      >
        <CustomIconButton
          size="small"
          icon={<ArrowForwardIos />}
          onClick={back}
        />
        <Slide
          container={containerRef.current}
          direction={aminDirection}
          in={aminStart}
        >
          <Stack
            justifyContent="space-between"
            width="100%"
            direction="row"
            boxShadow={50}
          >
            {list?.map?.((item, index) => {
              return (
                <NullCheck valueForCheck={index === start}>
                  <Stack
                    onClick={() => setRecommendDialog(item)}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                    spacing={0.5}
                    width="100%"
                  >
                    <Stack
                      position="relative"
                      direction="row"
                      padding={1}
                      justifyContent="space-between"
                      bgcolor="secondary.main"
                      sx={{ opacity: 0.9 }}
                    >
                      <Stack
                        sx={{
                          borderTopLeftRadius: 100,
                          borderTopRightRadius: 100,
                          transform: "rotate(45deg)",
                        }}
                        bgcolor="secondary.main"
                        top={-15}
                        right={-15}
                        zIndex={10}
                        position="absolute"
                      >
                        <FormatQuote fontSize="large" color="primary" />
                      </Stack>

                      <Typography zIndex={50} variant="h5" fontWeight="bold">
                        {item?.title}
                      </Typography>
                    </Stack>
                    <Stack
                      padding={1}
                      bgcolor="secondary.main"
                      height={100}
                      sx={{ opacity: 0.8 }}
                    >
                      <Typography overflow="hidden">
                        {lineText(item?.recommend)}
                      </Typography>
                    </Stack>
                  </Stack>
                </NullCheck>
              );
            })}
          </Stack>
        </Slide>

        <CustomIconButton
          size="small"
          icon={<ArrowBackIos />}
          onClick={forward}
        />
      </Stack>

      <NullCheck valueForCheck={recommendDialog}>
        <CustomDialog
          title={recommendDialog?.title}
          open={recommendDialog ? true : false}
          onClose={() => setRecommendDialog(null)}
        >
          <Stack padding={1}>
            <Typography variant="h6">
              {lineText(recommendDialog?.recommend)}
            </Typography>
          </Stack>
        </CustomDialog>
      </NullCheck>
    </Stack>
  );
};
export default Recommend;
