import { Divider } from "@mui/material";

const CustomDivider = ({ color, vertical }) => {
  return (
    <Divider
      sx={{ bgcolor: color ?? "primary.light" }}
      orientation={vertical ? "vertical" : "horizontal"}
      flexItem={vertical ? true : false}
    />
  );
};

export default CustomDivider;
