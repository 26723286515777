import { Stack, Typography } from "@mui/material"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { closedTab } from "../../../config/functions";
import Map from "../../../components/Map";
import NullCheck from "../../../components/NullCheck";
import CustomButton from "../../../components/CustomButton";

const Navigation = () => {
  const { guid } = useParams();
  const navigate = useNavigate();
  const data = useSelector((state) => state?.smartQr?.data?.navigation);
  const lat = data?.latLonl ? data?.latLonl?.split(",") : [];
  const position = lat.length > 0 ? [+lat[0], +lat[1]] : null;

  useEffect(() => {
    if(!data && guid)
      navigate(`/smartQr/${guid}`)

    const googleMap = data?.googleMap
    const waze = data?.waze

    if(!googleMap && waze)
      openWaze()

    else if(googleMap && !waze)
      openGoogleMap()
    }, [])
  
  const openWaze = () =>{
    window.open(`https://waze.com/ul?ll=${data?.latLonl}&navigate=yes`)
    closedTab()
  }

  const openGoogleMap = () =>{
      window.open(`https://maps.google.com/?ll=${data?.latLonl}`)
    closedTab()
  }

  
return(
        <NullCheck valueForCheck={data?.latLonl}>
          <Stack
            justifyContent="space-between"
            gap={2}
            height='100vh'
            padding={1}
            bgcolor="primary.main"
          >
            <Map position={position} height='80vh' width="100%" />

            <Stack spacing={2}>
              <CustomButton
                fullWidth
                fontBold
                height="100%"
                color="secondary"
                title="WAZE"
                onClick={openWaze}
              />
              <CustomButton
                fullWidth
                height="100%"
                fontBold
                color="secondary"
                title="GOOGLE_MAP"
                onClick={openGoogleMap}
              />
            </Stack>
          </Stack>
        </NullCheck>
)}
export default Navigation
