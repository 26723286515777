import { useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";

const CsvButton = ({ color, borderRadius, textColor }) => {
  const emp = useSelector((state) => state?.biz?.data?.emp);

  const CsvButtonCsv = () => {
    var contact = {
      name: `${emp.fn} ${emp.ln}`,
      phone: emp.tel,
      email: emp.email,
    };

    // create a vcard file
    var vcard =
      "BEGIN:VCARD\nVERSION:4.0\nFN:" +
      contact.name +
      "\nTEL;TYPE=work,voice:" +
      contact.phone +
      "\nEMAIL:" +
      contact.email +
      "\nEND:VCARD";
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);

    const newLink = document.createElement("a");
    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;

    newLink.click();
  };

  return (
    <CustomButton
      title="SAVE_CSV"
      color={color}
      borderRadius={borderRadius}
      textColor={textColor}
      onClick={CsvButtonCsv}
    />
  );
};
export default CsvButton;
