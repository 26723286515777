import { useForm } from "react-hook-form";
import CustomDialog from "../../components/CustomDialog";
import TextInput from "../../components/files/TextInput";
import CustomButton from "../../components/CustomButton";
import { Stack, Typography } from "@mui/material";
import { apiUrl } from "../../config/settings";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Password = ({ openPassword, setOpenPassword, getQrData }) => {
  const { t } = useTranslation();
  const { guid } = useParams();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    axios
      .get(`${apiUrl}/smartQr/checkPassword/${guid}/${values?.password}`)
      .then((response) => {
        getQrData();
        setOpenPassword(false);
      })
      .catch((error) => {
        setError("password", {
          type: "password",
          message: t("PASSWORD_ERROR"),
        });
        console.log("error", error);
      });
  };

  return (
    <CustomDialog title="PASSWORD" open={openPassword}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack textAlign='center' spacing={2}>
          <Typography color='error'>
            {t('PASSWORD_INFO')}
          </Typography>
          <TextInput
          type='password'
            width={{ xs: "100%", md: "auto" }}
            required
            errors={errors}
            name="password"
            title="PASSWORD"
            control={control}
          />
          <CustomButton title="NEXT" submit />
        </Stack>
      </form>
    </CustomDialog>
  );
};
export default Password;
