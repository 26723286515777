import { useRef } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const TextOptionsInput = ({
  control,
  title,
  name,
  disabled,
  required,
  errors,
  width,
  validation,
  options,
  removeItemWithList,
}) => {
  const refInput = useRef(null);
  const { t } = useTranslation();
  const error = errors ? errors[name]?.ref?.name : false;

  const removeItem = (e, item) => {
    e.stopPropagation();
    removeItemWithList(item);
  };

  return (
    <Stack>
      <Stack alignItems="end" direction="row" gap={0.5}>
        <Typography fontSize={12}>
          {`${required ? "*" : ""}${t(title)}:`}
        </Typography>
      </Stack>

      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? t("REQUIRED") : false,
          pattern: {
            value: validation?.regex,
            message: t(validation?.massge),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            freeSolo
            disableClearable
            value={value}
            disabled={disabled}
            options={options}
            inputRef={refInput}
            onChange={(e, v) => onChange(v)}
            sx={{ width: width ? width : 200 }}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Stack
                  key={key}
                  width="100%"
                  paddingX={1}
                  direction="row"
                  gap={1}
                  {...optionProps}
                >
                  <IconButton size="small" onClick={(e) => removeItem(e, key)}>
                    <Close fontSize="small" />
                  </IconButton>
                  <Typography variant="subtitle2">{key}</Typography>
                </Stack>
              );
            }}
            renderInput={(params) => (
              <TextField
                error={error === name ? true : false}
                onChange={(e) => onChange(e.target.value)}
                {...params}
              />
            )}
          />
        )}
      />
    </Stack>
  );
};
export default TextOptionsInput;
