import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import { apiUrl, imageUrl } from "../../config/settings";

const Header = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const data = useSelector((state) => state?.biz?.data);

  return (
    <Stack
      bgcolor="primary.main"
      minHeight={230}
      maxHeight={300}
      position="relative"
    >
      <Stack>
        <img
          style={{
            width: "100%",
            height: 145,
            objectFit: "cover",
            borderRadius: "0% 0% 20% 0%",
          }}
          src={data?.imageHeader}
        />
      </Stack>

      <Stack
        width="100%"
        direction="row"
        gap={1}
        alignItems="end"
        position="absolute"
        top={95}
        right={15}
      >
        <img
          style={{
            width: 130,
            height: 130,
            objectFit: "cover",
            borderRadius: 100,
            border: `3px solid ${theme.palette.primary.main}`,
          }}
          src={data?.emp?.image}
        />
        <Stack marginBottom={3}>
          <Typography variant="h5" color="secondary">
            {`${data?.emp?.fn} ${data?.emp?.ln}`}
          </Typography>

          <Typography fontWeight="bold" color="secondary">
            {`${data?.emp?.role}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default Header;
