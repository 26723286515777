import { createSlice } from "@reduxjs/toolkit";

const sysSlice = createSlice({
  name: "sys",
  initialState: {
    showLoading: true,
  },
  reducers: {
    setShowLoading(state, action) {
      state.showLoading = action.payload;
    },
  },
});

export const { setShowLoading } = sysSlice.actions;
export default sysSlice.reducer;
