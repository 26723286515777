import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";

const Description = () => {
  const data = useSelector((state) => state?.biz?.data);
  return (
    <Stack
      sx={{
        ".bottom-arrow:after": {
          position: "absolute",
          left: 0,
          right: 0,
          margin: "0 auto",
          width: 0,
          height: 0,
          borderTop: "25px solid #6A0136",
          borderLeft: "50px solid transparent",
          borderRadius: "50px solid transparent",
        },
      }}
      padding={1}
      spacing={2}
      bgcolor="secondary.main"
    >
      <NullCheck valueForCheck={data?.bizDescTitle}>
        <Stack>
          <Typography variant="h6" fontWeight="bold" color="primary">
            {data?.bizDescTitle}
          </Typography>
          <Divider sx={{ bgcolor: "primary.light" }} />
        </Stack>
      </NullCheck>

      <Typography className="display-linebreak" color="primary">
        {data?.bizDesc}
      </Typography>
    </Stack>
  );
};
export default Description;
