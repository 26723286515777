import { IconButton, Stack, useTheme } from "@mui/material";
import CustomDivider from "../../components/CustomDivider";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useSelector } from "react-redux";

const ToolsBar = ({ borderRadius, notSticky }) => {
  const theme = useTheme();
  const emp = useSelector((state) => state?.biz?.data?.emp);

  const openWhatsApp = () => {
    window.open(`https://wa.me/972${emp?.tel}`);
  };

  const openTel = () => {
    window.open(`tel:${emp?.tel}`);
  };

  const openEmail = () => {
    window.open(`mailto:${emp?.email}`);
  };

  console.log("notSticky", notSticky);

  return (
    <Stack
      borderRadius={borderRadius ?? "0"}
      position={notSticky ? "" : "sticky"}
      bottom={0}
      zIndex={99999}
      height={40}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="secondary.main"
      borderTop={`5px solid ${theme.palette.primary.main}`}
      padding={1}
    >
      {/* Tel */}
      <Stack onClick={openTel} alignItems="center" width="100%">
        <IconButton>
          <WifiCalling3Icon color="primary" />
        </IconButton>
      </Stack>

      <CustomDivider vertical />

      {/* WhatsApp */}
      <Stack onClick={openWhatsApp} alignItems="center" width="100%">
        <IconButton>
          <WhatsAppIcon color="primary" />
        </IconButton>
      </Stack>

      <CustomDivider vertical />

      {/* Email */}
      <Stack onClick={openEmail} alignItems="center" width="100%">
        <IconButton>
          <TelegramIcon color="primary" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
export default ToolsBar;
