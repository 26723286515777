import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../config/settings";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Password from "./Password";
import { useDispatch } from "react-redux";
import { SMART_QR_TYPE_ID } from "./const";
import { setShowLoading } from "../../store/sysSlice";
import { setSmartQrData } from "../../store/smartQrSlice";
import { closedTab } from "../../config/functions";

const SmartQr = () => {
  const dispatch = useDispatch();
  const { guid } = useParams();
  const navigate = useNavigate();
  const [openPassword, setOpenPassword] = useState(false);

  useEffect(() => {
    if (guid) {
      axios
        .get(`${apiUrl}/smartQr/hasPassword/${guid}`)
        .then((response) => {
          if (response.status === 200) {
            const hasPassword = response?.data?.data;
            if (hasPassword) {
              setOpenPassword(true);
              dispatch(setShowLoading(false));
            } else getQrData();
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
      } else {
        closedTab();
      }
    }, [guid]);
    
    const getQrData = () => {
      axios
      .get(`${apiUrl}/smartQr/${guid}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.data;
          dispatch(setSmartQrData( data));
          navigateOfData(data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const navigateOfData = (data) => {
    dispatch(setShowLoading(false));
    switch (data.typeId) {
      case SMART_QR_TYPE_ID.URL:
        closedTab(data?.url);
        break
      case SMART_QR_TYPE_ID.CONTACT:
        downloadCsv(data?.contact);
        break
      case SMART_QR_TYPE_ID.TEXT:
        navigate(`/smartQr/${guid}/text`);
        break
      case SMART_QR_TYPE_ID.NAVIGATION:
        navigate(`/smartQr/${guid}/navigation`);
        break
      case SMART_QR_TYPE_ID.CLIENT_SERVICES_CALL:
        navigate(`/clientServiceCall/${data?.clientServiceCall?.baseUrl}`);
        break
    }
  };

  const downloadCsv = (data) => {
    var contact = {
      name: data.name,
      phone: data.tel,
      email: data.email,
    };

    // create a vcard file
    var vcard =
      "BEGIN:VCARD\nVERSION:4.0\nFN:" +
      contact.name +
      "\nTEL;TYPE=work,voice:" +
      contact.phone +
      "\nEMAIL:" +
      contact.email +
      "\nEND:VCARD";
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);

    const newLink = document.createElement("a");
    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;

    newLink.click();

    closedTab();
  };


  return openPassword ? (
    <Password
      getQrData={getQrData}
      openPassword={openPassword}
      setOpenPassword={setOpenPassword}
    />
  ) : (
    <Loading />
  );
};
export default SmartQr;
