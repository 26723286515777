import { Stack, Typography } from "@mui/material";
import { sysVersion } from "../config/settings";

const Footer = () => {
  return (
    <Stack
      direction="row"
      padding={2}
      alignItems="center"
      justifyContent="space-between"
      bgcolor="primary.main"
    >
      <Typography fontWeight="bold" fontSize={14} color="secondary">
        {`v-${sysVersion}`}
      </Typography>
      <Typography fontWeight="bold" fontSize={14} color="secondary">
        Powered by MyBee
      </Typography>
    </Stack>
  );
};
export default Footer;
