import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomButton = ({
  onClick,
  size,
  height,
  width,
  variant,
  title,
  submit,
  autoFocus,
  fullWidth,
  underline,
  fontSize,
  disabled,
  color,
  textColor,
  fontBold,
  icon,
  borderRadius,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      sx={{
        width: width,
        height: height,
        borderRadius: borderRadius ?? "0",
      }}
      color={color ?? "primary"}
      disabled={disabled ? true : false}
      variant={variant ?? "contained"}
      size={size ? size : "small"}
      onClick={onClick ? onClick : () => {}}
      type={submit ? "submit" : "button"}
      autoFocus={autoFocus ? true : false}
      fullWidth={fullWidth ? true : false}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {icon}
        <Typography
          color={
            textColor
              ? textColor
              : color === "secondary"
              ? "primary"
              : "secondary"
          }
          fontWeight={fontBold ? "bold" : "none"}
          fontSize={fontSize ?? 16}
          sx={{ textDecoration: underline ? "underline" : "" }}
        >
          {t(title)}
        </Typography>
      </Stack>
    </Button>
  );
};
export default CustomButton;
