import { Info } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";

const TextInput = ({
  control,
  rows,
  title,
  name,
  disabled,
  required,
  errors,
  width,
  type,
  validation,
  autoComplete,
}) => {
  const refInput = useRef(null);
  const { t } = useTranslation();
  const [openMassge, setOpenMassge] = useState(false);
  const error = errors ? errors[name]?.ref?.name : false;
  const massge = errors ? errors[name]?.message : "";

  return (
    <Stack>
      <Stack alignItems="end" direction="row" gap={0.5}>
        <Typography fontSize={12}>
          {`${required ? "*" : ""}${t(title)}:`}
        </Typography>
      </Stack>

      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? t("REQUIRED") : false,
          pattern: {
            value: validation?.regex,
            message: t(validation?.massge),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            sx={{ width: width ? width : 200 }}
            inputRef={refInput}
            type={type ?? 'text'}
            error={error === name ? true : false}
            disabled={disabled}
            multiline={rows ? true : false}
            rows={rows}
            autoComplete={autoComplete}
            value={value}
            InputProps={{
              endAdornment: massge ? (
                <Tooltip title={massge}>
                  <IconButton onClick={() => setOpenMassge(true)}>
                    <Info color="error" fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null,
            }}
            onChange={onChange}
          />
        )}
      />
      {massge ? (
        <CustomDialog
          title={t("ERROR")}
          open={openMassge}
          onClose={() => setOpenMassge(false)}
        >
          {t(massge)}
        </CustomDialog>
      ) : null}
    </Stack>
  );
};
export default TextInput;
