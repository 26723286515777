import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/files/TextInput";
import { celIsraeli, email } from "../../utils/validations";
import CustomButton from "../../components/CustomButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "../../config/settings";
import Lottie from "lottie-react";
import loadingButton from "../../assets/loadingButton.json";
import { useState } from "react";
import { PLANS_TYPE_ID, PLUGIN_NAME } from "../../config/const";
import NullCheck from "../../components/NullCheck";

const ContactUs = () => {
  const { t } = useTranslation();
  const empid = useSelector((state) => state?.biz?.data?.emp?.id);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    setLoading(true);
    const data = {
      employeeId: empid,
      ...values,
    };

    axios
      .post(`${apiUrl}/webAppMessage`, data)
      .then((res) => {
        setSucceeded(true);

        reset({
          name: "",
          tel: "",
          email: "",
          title: "",
          content: "",
        });

        const timer = setTimeout(() => {
          setLoading(false);
          setSucceeded(false);
          clearTimeout(timer);
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          width={250}
          height={250}
          justifyContent="center"
          alignItems="center"
        >
          <Lottie animationData={loadingButton} loop={true} />
          {succeeded ? (
            <Typography fontWeight="bold">{t("SEND_SUCCEEDED")}</Typography>
          ) : null}
        </Stack>
      </Stack>
    );
  }

  return (
    <NullCheck valueForCheck={plugins?.includes(PLUGIN_NAME.CONTACT_US)}>
      <Stack padding={1} bgcolor="secondary.main">
        <Stack
          alignItems={{ xs: "none", md: "center" }}
          border={`2px solid black`}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Stack padding={1}>
                <Typography variant="h6" fontWeight="bold" color="primary">
                  {t("SEMD_MASSGE")}
                </Typography>
                <Divider sx={{ bgcolor: "secondary.light" }} />
              </Stack>

              <Stack
                padding={1}
                gap={{ xs: 0, md: 3 }}
                direction={{ xs: "column", md: "row" }}
              >
                <Stack>
                  <TextInput
                    width={{ xs: "100%", md: "auto" }}
                    required
                    errors={errors}
                    name="name"
                    title="NAME"
                    control={control}
                  />
                  <TextInput
                    width={{ xs: "100%", md: "auto" }}
                    required
                    errors={errors}
                    validation={celIsraeli}
                    name="tel"
                    title="TEL"
                    control={control}
                  />
                  <TextInput
                    width={{ xs: "100%", md: "auto" }}
                    errors={errors}
                    validation={email}
                    name="email"
                    title="EMAIL"
                    control={control}
                  />
                </Stack>

                <Stack>
                  <TextInput
                    width={{ xs: "100%", md: "auto" }}
                    required
                    errors={errors}
                    name="title"
                    title="TITLE"
                    control={control}
                  />
                  <TextInput
                    width={{ xs: "100%", md: "auto" }}
                    rows={4}
                    required
                    errors={errors}
                    name="content"
                    title="CONTENT"
                    control={control}
                  />
                </Stack>
              </Stack>

              <Stack padding={1}>
                <CustomButton loading title="SEND" submit />
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </NullCheck>
  );
};
export default ContactUs;
