import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";

const Description = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state?.biz?.data);
  return (
    <Stack padding={1} spacing={2} bgcolor="primary.main">
      <NullCheck valueForCheck={data?.bizDescTitle}>
        <Stack>
          <Typography variant="h6" fontWeight="bold" color="secondary">
            {data?.bizDescTitle}
          </Typography>
          <Divider sx={{ bgcolor: "secondary.light" }} />
        </Stack>
      </NullCheck>

      <Typography className="display-linebreak" color="secondary">
        {data?.bizDesc}
      </Typography>
    </Stack>
  );
};
export default Description;
