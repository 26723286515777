import { Box, Slide, Stack, Zoom } from "@mui/material";
import Header from "./Header";
import Footer from "../../components/Footer";
import { useEffect, useRef, useState } from "react";
import CustomIconButton from "../../components/CustomIconButton";
import { PinDrop } from "@mui/icons-material";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import WaitingForDriver from "./WaitingForDriver";
import CreateOrder from "./CreateOrder";
import Answer from "./Answer";
import NullCheck from "../../components/NullCheck";

const OrderTaxiTemp = () => {
  const containerRef = useRef();
  const [myLoc, setMyLoc] = useState(false);
  const [step, setStep] = useState(1);
  const [orderData, setOrderData] = useState(null);
  const [applicationDetails, setApplicationDetails] = useState(null);

  const LocationMarker = ({ myLoc }) => {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    const map = useMap();

    useEffect(() => {
      getLoc();
    }, [map, myLoc]);

    const getLoc = () => {
      map.locate().on("locationfound", function (e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        setBbox(e.bounds.toBBoxString().split(","));
      });
    };

    const onSubmit = () => {};

    return position === null ? null : <Marker position={position} />;
  };

  return (
    <Stack
      position="relative"
      spacing={0.5}
      height="100%"
      justifyContent="space-between"
      sx={{
        overflowX: "hidden",
        overflowY: "auto",
        scrollbarWidth: "none",
      }}
    >
      <Stack>
        <Header />

        {/* Map */}
        <NullCheck valueForCheck={step === 1}>
          <Stack
            width="100%"
            position="relative"
            borderRadius={"0"}
            borderColor="white"
          >
            <MapContainer
              style={{ borderRadius: "0", height: 200 }}
              center={[29.557398864743032, 34.94577294421766]}
              zoom={18}
              minZoom={14}
              maxZoom={18}
              zoomControl={false}
              scrollWheelZoom={true}
            >
              <LocationMarker myLoc={myLoc} />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>

            <Stack position="absolute" bottom={10} left={10} zIndex={999}>
              <CustomIconButton
                icon={<PinDrop />}
                onClick={() => setMyLoc((bool) => !bool)}
              />
            </Stack>
          </Stack>
        </NullCheck>
      </Stack>

      <Stack height="100%">
        <Box
          ref={containerRef}
          padding={2}
          height="100%"
          bgcolor="secondary.main"
        >
          {/* Step 1 */}
          <Slide
            direction="left"
            container={containerRef.current}
            in={step === 1}
          >
            <Stack>
              <CreateOrder
                step={step}
                setStep={setStep}
                setApplicationDetails={setApplicationDetails}
                applicationDetails={applicationDetails}
              />
            </Stack>
          </Slide>

          {/* Step 2 */}
          <Zoom container={containerRef.current} in={step === 2}>
            <Stack>
              <WaitingForDriver
                step={step}
                applicationDetails={applicationDetails}
                setApplicationDetails={setApplicationDetails}
                setStep={setStep}
                setOrderData={setOrderData}
              />
            </Stack>
          </Zoom>

          {/* Step 3 */}
          <Zoom container={containerRef.current} in={step === 3}>
            <Stack>
              <Answer
                step={step}
                setStep={setStep}
                setOrderData={setOrderData}
                setApplicationDetails={setApplicationDetails}
                orderData={orderData}
              />
            </Stack>
          </Zoom>
        </Box>
        <Footer />
      </Stack>
    </Stack>
  );
};
export default OrderTaxiTemp;
