import { createSlice } from "@reduxjs/toolkit";

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState: {
    selectedLang: 1,
    user: null,
  },
  reducers: {
    setSelectedLang(state, action) {
      state.selectedLang = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setSelectedLang, setUser } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
