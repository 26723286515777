import { useForm } from "react-hook-form";
import TextInput from "../../components/files/TextInput";
import axios from "axios";
import { apiUrl } from "../../config/settings";
import { celIsraeli } from "../../utils/validations";
import CustomButton from "../../components/CustomButton";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextOptionsInput from "../../components/files/TextOptionsInput";
import CustomDialog from "../../components/CustomDialog";

const CreateOrder = ({ step, setStep, setApplicationDetails }) => {
  const { t } = useTranslation();
  const [addrList, setAddrList] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({});

    let name = localStorage.getItem("clientName");
    let tel = localStorage.getItem("clientTel");
    let addrs = localStorage.getItem("clientAddrs");

    if (name) setValue("clientName", JSON.parse(name));
    if (tel) setValue("clientTel", JSON.parse(tel));
    if (addrs) setAddrList(JSON.parse(addrs));
  }, [step]);

  const addAddrInLocalStorge = (addr) => {
    if (addr) {
      let addrList = localStorage.getItem("clientAddrs");
      addrList = JSON.parse(addrList);
      let newList = [];

      if (!addrList) newList = [addr];
      else if (!addrList.includes(addr)) {
        if (addrList.length >= 10) {
          const removeLast = addrList.filter((addr, index) => index !== 0);
          newList = [...removeLast, addr];
        } else {
          newList = [...addrList, addr];
        }
      } else {
        newList = [...addrList];
      }

      localStorage.setItem("clientAddrs", JSON.stringify(newList));
    }
  };

  const removeItemWithList = (item) => {
    let newList = [];
    newList = addrList.filter((addr) => addr !== item);
    localStorage.setItem("clientAddrs", JSON.stringify(newList));
    setAddrList(newList);
  };

  const onSubmit = (values) => {
    if (values?.collectionAddr === values?.destinationAddr) {
      setErrorMsg("SAME_ADDRESSES_ERROR");
      return;
    }

    let data = { ...values };
    localStorage.setItem("clientName", JSON.stringify(values?.clientName));
    localStorage.setItem("clientTel", JSON.stringify(values?.clientTel));
    axios
      .post(`${apiUrl}/orderTaxi`, data)
      .then((res) => {
        addAddrInLocalStorge(values?.collectionAddr);
        data.id = res?.data?.itemId;
        setApplicationDetails(data);
        setStep(2);
      })
      .catch((error) => {});
  };

  if (step !== 1) return;

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Stack padding={2} border={2} spacing={1} borderColor="primary">
        <Typography fontWeight="bold" textAlign="center">
          {t("TAXI_ORDER")}
        </Typography>
        <TextInput
          width={{ xs: "100%", md: "auto" }}
          errors={errors}
          name="clientName"
          required
          title="CLIENT_NAME"
          control={control}
        />
        <TextInput
          width={{ xs: "100%", md: "auto" }}
          errors={errors}
          required
          validation={celIsraeli}
          name="clientTel"
          title="CLIENT_TEL"
          control={control}
        />

        <TextOptionsInput
          width={{ xs: "100%", md: "auto" }}
          errors={errors}
          required
          removeItemWithList={removeItemWithList}
          options={addrList}
          name="collectionAddr"
          title="COLLECTION_ADDR"
          control={control}
        />
        <TextOptionsInput
          width={{ xs: "100%", md: "auto" }}
          errors={errors}
          required
          removeItemWithList={removeItemWithList}
          options={addrList}
          name="destinationAddr"
          title="DESTINATION_ADDR"
          control={control}
        />

        <CustomButton fullWidth title="SEND" submit />
      </Stack>

      <CustomDialog
        open={errorMsg ? true : false}
        onClose={() => setErrorMsg(null)}
        title="ERROR"
      >
        <Stack>
          <Typography>{t(errorMsg)}</Typography>
        </Stack>
      </CustomDialog>
    </form>
  );
};
export default CreateOrder;
