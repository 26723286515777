import { Backdrop, Stack, Zoom } from "@mui/material";
import Lottie from "lottie-react";
import loading from "../assets/loading.json";
import loadingText from "../assets/loadingText.json";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Loading = () => {
  const showLoading = useSelector((state) => state?.sys.showLoading);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (showLoading) setOpen(true);
    else {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [showLoading]);

  return (
    <Backdrop
      sx={{
        zIndex: 999999,
        // zIndex: (theme) => theme.zIndex.drawer + 1,
        "&.MuiBackdrop-root": {
          bgcolor: "white",
          // bgcolor: "rgba(0 , 0, 0, 0.1)",
          // backdropFilter: "blur(2px)",
        },
      }}
      open={open}
    >
      <Zoom in={open}>
        <Stack
          bgcolor="white"
          alignItems="center"
          justifyContent="center"
          borderRadius={100}
          // padding={2}
          paddingTop={1}
          paddingX={1}
          maxWidth={170}
        >
          <Lottie animationData={loading} loop={true} />
          <Lottie animationData={loadingText} loop={true} />
        </Stack>
      </Zoom>
    </Backdrop>
  );
};
export default Loading;
