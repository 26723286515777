import axios from "axios";
import { apiUrl } from "../../config/settings";
import { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import waitingForDriver from "../../assets/waitingForDriver.json";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";

const WaitingForDriver = ({
  step,
  setStep,
  setOrderData,
  applicationDetails,
  setApplicationDetails,
}) => {
  const { t } = useTranslation();
  let timer = null;

  useEffect(() => {
    if (applicationDetails?.id) {
      timer = setInterval(() => {
        checkOrder(applicationDetails?.id);
      }, 8000);
      return () => clearInterval(timer);
    }
  }, [applicationDetails?.id]);

  const checkOrder = (orderId) => {
    axios
      .get(`${apiUrl}/orderTaxi/checkStOrder/${orderId}`)
      .then((res) => {
        if (res?.data?.id === 1) {
          setOrderData(res?.data?.data);
          setStep(3);
          clearInterval(timer);
        }
      })
      .catch((error) => {});
  };

  const cancelOrder = (orderId) => {
    axios
      .patch(`${apiUrl}/orderTaxi/cancelOrder/${orderId}`)
      .then((res) => {
        if (res?.data?.id === 1) {
          clearInterval(timer);
          setApplicationDetails(null);
          setStep(1);
        }
      })
      .catch((error) => {});
  };

  if (step !== 2) return;

  return (
    <Stack spacing={2} alignItems="center">
      <Stack>
        <Typography variant="h5" textAlign="center" fontWeight="bold">
          {t("LOOKING_TAXI")}
        </Typography>

        <Typography textAlign="center" fontWeight="bold" color="error">
          {t("PLEASE_NOT_CLOSE_PAGE")}
        </Typography>
      </Stack>

      <Stack width={300}>
        <Lottie autoPlay animationData={waitingForDriver} loop />
      </Stack>

      <Stack direction="row" gap={1}>
        <Typography>{applicationDetails?.collectionAddr}</Typography>
        <KeyboardDoubleArrowLeft />
        <Typography>{applicationDetails?.destinationAddr}</Typography>
      </Stack>

      <CustomButton
        fullWidth
        title="CANCELLATION_ORDER"
        onClick={() => cancelOrder(applicationDetails?.id)}
        color="error"
      />
    </Stack>
  );
};
export default WaitingForDriver;
