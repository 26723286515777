import { Stack, Typography } from "@mui/material"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const Text = () => {
  const data = useSelector((state) => state?.smartQr?.data);
  const { guid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if(!data && guid)
      navigate(`/smartQr/${guid}`)
  }, [])

  
return(
    <Stack padding={2}>
      <Typography className="display-linebreak">
          {data?.text}
        </Typography>
    </Stack>
)
}
export default Text
