import { useSelector } from "react-redux";

import { TEMP_TYPE_ID } from "./const";
import BasicTemp from "../templates/basic";
import ClassicTemp from "../templates/classic";
import Classic1Temp from "../templates/classic1";
import WavesTemp from "../templates/waves";
import OrderTaxiTemp from "../templates/orderTaxi";
import Design1Temp from "../templates/design1";
import { Stack } from "@mui/material";
import NullCheck from "../components/NullCheck";
import ChangeTemp from "../components/ChangeTemp";

const TemplatesRoutes = () => {
  const tempTypeId = useSelector((state) => state?.biz?.data?.tempTypeId);
  const data = useSelector((state) => state?.biz?.data);

  return (
    <Stack>
      <NullCheck valueForCheck={data?.isBizExample}>
        <ChangeTemp />
      </NullCheck>

      {tempTypeId === TEMP_TYPE_ID.BASIC ? (
        <BasicTemp />
      ) : tempTypeId === TEMP_TYPE_ID.CLASSIC ? (
        <ClassicTemp />
      ) : tempTypeId === TEMP_TYPE_ID.BUBBLE ? (
        <WavesTemp />
      ) : tempTypeId === TEMP_TYPE_ID.ORDER_TAXI ? (
        <OrderTaxiTemp />
      ) : tempTypeId === TEMP_TYPE_ID.CLASSIC_1 ? (
        <Classic1Temp />
      ) : tempTypeId === TEMP_TYPE_ID.DESIGN_1 ? (
        <Design1Temp />
      ) : null}
    </Stack>
  );
};
export default TemplatesRoutes;
