import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DEFAULT_USER from "../../assets/default_user.jpg";
const Header = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const data = useSelector((state) => state?.biz?.data);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (data?.emp?.image) setImage(data?.emp?.image);
    else if (data?.imageLogo) setImage(data?.imageLogo);
    else setImage(DEFAULT_USER);
  }, [data]);

  return (
    <Stack
      bgcolor="primary.main"
      minHeight={300}
      maxHeight={400}
      position="relative"
    >
      <Stack>
        <img
          style={{
            width: "100%",
            height: 145,
            objectFit: "cover",
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,
          }}
          src={data?.imageHeader}
        />
      </Stack>

      <Stack width="100%" alignItems="center" position="absolute" top={70}>
        <img
          style={{
            width: 150,
            height: 150,
            objectFit: "cover",
            borderRadius: 100,
            border: `3px solid ${theme.palette.primary.main}`,
          }}
          src={image}
        />
        <Stack alignItems="center">
          <Typography variant="h4" color="secondary">
            {`${data?.emp?.fn} ${data?.emp?.ln}`}
          </Typography>
          <Typography
            variant="h6"
            color="secondary"
          >{`${data?.emp?.role}`}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default Header;
