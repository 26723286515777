import { MenuItem, Select, Stack, Typography } from "@mui/material";
import NullCheck from "./NullCheck";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TEMP_TYPES } from "../config/const";
import { useTranslation } from "react-i18next";
import { setBizData } from "../store/cardSlice";
import { setShowLoading } from "../store/sysSlice";

const ChangeTemp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => state?.biz?.data);
  const [temp, setTemp] = useState(TEMP_TYPES[0].id);

  useEffect(() => {
    setTemp(data?.tempTypeId);
  }, []);

  const onChangeTemp = (e) => {
    let newData = { ...data };
    newData.tempTypeId = e;
    dispatch(setBizData(newData));
    setTemp(e);
  };

  return (
    <NullCheck valueForCheck={data?.isBizExample}>
      <Stack
        gap={2}
        alignItems="center"
        direction="row"
        padding={1}
        borderBottom={3}
        borderColor="secondary.main"
        bgcolor="white"
        position="sticky"
        zIndex={50}
        top={0}
      >
        <Typography width="30%">{t("EXAMPLE_TEMP")}</Typography>
        <Select
          size="small"
          fullWidth
          value={temp}
          onChange={(e) => onChangeTemp(e.target.value)}
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                bgcolor: "GrayText",
                color: "white",
              },
            },
          }}
        >
          {TEMP_TYPES.map((temp, index) => {
            return (
              <MenuItem key={index} value={temp?.id}>
                <Typography>{t(temp?.name)}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    </NullCheck>
  );
};
export default ChangeTemp;
