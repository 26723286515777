import {
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";

const ClientItems = ({ itemList, setItemList, clientData }) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(clientData?.items[0] ?? "");

  const addProd = () => {
    if (selectedItem === 1) return;
    setItemList((list) => [...list, selectedItem]);
    setSelectedItem(clientData?.items[0] ?? "");
  };

  const removeProd = (id) => {
    let newList = [...itemList];
    newList = newList.filter((prod, index) => index !== id);
    setItemList(newList);
  };

  const changeItemText = (data) => {
    const text = data?.text;
    const indexItem = data?.indexItem;
    let newList = [...itemList];
    const item = newList[indexItem].split("-")[0];
    newList[indexItem] = `${item} - ${text}`;

    setItemList(newList);
  };

  if (!clientData) return;

  return (
    <Stack padding={2} spacing={2}>
      <Stack direction="row" gap={2}>
        <Select
          sx={{ width: 200, paddingX: 1 }}
          size="small"
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
        >
          {clientData?.items.map((item, index) => {
            return (
              item?.isWebSale && (
                <MenuItem sx={{ minWidth: 200 }} key={index} value={item.name}>
                  <Typography>{item.name}</Typography>
                </MenuItem>
              )
            );
          })}
        </Select>

        <CustomButton size="small" title="ADD_ITEM" onClick={addProd} />
      </Stack>

      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {itemList?.length > 0 ? (
          itemList?.map((item, index) => (
            <Fragment key={index}>
              <ListItem>
                <Stack
                  width="100%"
                  alignItems="start"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Stack spacing={1}>
                    <Typography textAlign="start" fontWeight="bold">
                      {item?.split("-")[0]}
                    </Typography>
                    <TextField
                      placeholder={t("DESC_PROBLEM")}
                      onChange={(e) =>
                        changeItemText({
                          text: e.target.value,
                          indexItem: index,
                        })
                      }
                      size="small"
                    />
                  </Stack>

                  <IconButton onClick={() => removeProd(index)}>
                    <Delete />
                  </IconButton>
                </Stack>
              </ListItem>
              <Divider />
            </Fragment>
          ))
        ) : (
          <Typography>{t("ADD_ITEMS")}</Typography>
        )}
      </List>
    </Stack>
  );
};
export default ClientItems;
