import Lottie from "lottie-react";
import succeeded from "../../assets/happy_face.json";
import error from "../../assets/sad_face.json";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import NullCheck from "../../components/NullCheck";
import { ST_TYPE_ID } from "./const";

const Answer = ({ step, setStep, orderData, setApplicationDetails }) => {
  const { t } = useTranslation();

  if (step !== 3) return;

  const newOrder = () => {
    setStep(1);
    setApplicationDetails(null);
  };

  return (
    <Stack
      alignItems="center"
      padding={2}
      spacing={2}
      // border={2}
      // borderColor="primary"
    >
      <Stack width={180}>
        <NullCheck
          valueForCheck={orderData?.stTypeId === ST_TYPE_ID.ASSOCIATED}
        >
          <Lottie autoPlay animationData={succeeded} />
        </NullCheck>

        <NullCheck valueForCheck={orderData?.stTypeId === ST_TYPE_ID.CANCEL}>
          <Lottie autoPlay animationData={error} />
        </NullCheck>
      </Stack>

      <Stack spacing={1} alignItems="center">
        <Typography textAlign="center" variant="h4">
          {t(
            orderData?.stTypeId === ST_TYPE_ID.ASSOCIATED
              ? "DRIVER_ON_WAY"
              : "CANCEL_ORDER"
          )}
        </Typography>

        <NullCheck valueForCheck={orderData?.approximateTime}>
          <Typography variant="h6">
            {`${t("APPROXIMATE_TIME")}:
             ${orderData?.approximateTime}
              ${t("MINUTES")}`}
          </Typography>
        </NullCheck>

        <NullCheck valueForCheck={orderData?.carMumber}>
          <Typography variant="h6">
            {`${t("TAXI_NUMBER")}: ${orderData?.carMumber}`}
          </Typography>
        </NullCheck>
      </Stack>

      <CustomButton title="NEW_ORDER" onClick={newOrder} />
    </Stack>
  );
};
export default Answer;
