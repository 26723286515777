import React, { useEffect, useRef, useState } from "react";
import { PLUGIN_NAME } from "../../config/const";
import { apiUrl } from "../../config/settings";
import NullCheck from "../../components/NullCheck";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomIconButton from "../../components/CustomIconButton";
import { Slide, Stack, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const VideoCarousel = ({ bgColor }) => {
  const containerRef = useRef(null);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const [list, setList] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(1);
  const [aminDirection, setAminDirection] = useState("right");
  const [aminStart, setAminStart] = useState(true);

  useEffect(() => {
    if (plugins?.includes(PLUGIN_NAME.RECOMMEND)) {
      axios.get(`${apiUrl}/videoCarousel`).then((response) => {
        setList(response?.data?.data);
      });
    }
  }, []);

  const forward = () => {
    setAminDirection("left");
    const timer = setTimeout(() => {
      setStart((data) => (end < list?.length ? data + 1 : 0));
      setEnd((data) => (end < list?.length ? data + 1 : 1));
      startAnim(1);
      clearTimeout(timer);
    }, 5);
  };

  const back = () => {
    setAminDirection("right");
    const timer = setTimeout(() => {
      setStart((data) => (start > 0 ? data - 1 : list?.length - 1));
      setEnd((data) => (start > 0 ? data - 1 : list?.length));
      startAnim(2);
      clearTimeout(timer);
    }, 5);
  };

  const startAnim = (type) => {
    setAminStart(false);
    const timer = setTimeout(() => {
      if (type === 1) setAminDirection("right");
      else setAminDirection("left");

      setAminStart(true);
      clearTimeout(timer);
    }, 500);
  };

  const getId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  if (!plugins?.includes(PLUGIN_NAME.RECOMMEND)) return;

  return (
    <Stack alignItems="center" padding={1} bgcolor={bgColor ?? "primary.main"}>
      <Stack
        overflow="hidden"
        padding={2}
        width="100%"
        direction="row"
        alignItems="center"
      >
        <NullCheck valueForCheck={list.length > 1}>
          <CustomIconButton
            size="small"
            icon={<ArrowForwardIos />}
            onClick={back}
          />
        </NullCheck>
        <Slide
          container={containerRef.current}
          direction={aminDirection}
          in={aminStart}
        >
          <Stack
            justifyContent="space-between"
            width="100%"
            direction="row"
            boxShadow={50}
          >
            {list?.map?.((item, index) => {
              console.log(getId(item?.videoUrl));

              return (
                <NullCheck valueForCheck={index === start}>
                  <Stack spacing={0.5} width="100%">
                    <iframe
                      height={250}
                      // src={`${item?.videoUrl}`}
                      src={`https://www.youtube.com/embed/${getId(
                        item?.videoUrl
                      )}${item?.autoplay ? "?autoplay=1" : ""}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                    <NullCheck valueForCheck={item?.showTitle}>
                      <Stack
                        padding={1}
                        alignItems="center"
                        bgcolor="secondary.main"
                        sx={{ opacity: 0.9 }}
                      >
                        <Typography zIndex={50} fontWeight="bold">
                          {item?.title}
                        </Typography>
                      </Stack>
                    </NullCheck>
                  </Stack>
                </NullCheck>
              );
            })}
          </Stack>
        </Slide>
        <NullCheck valueForCheck={list.length > 1}>
          <CustomIconButton
            size="small"
            icon={<ArrowBackIos />}
            onClick={forward}
          />
        </NullCheck>
      </Stack>
    </Stack>
  );
};
export default VideoCarousel;
