import { IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";

/** icons */
import TwitterIcon from "@mui/icons-material/Twitter";
import HomeIcon from "@mui/icons-material/Home";
import WebIcon from "@mui/icons-material/Web";
import PublicIcon from "@mui/icons-material/Public";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import RedditIcon from "@mui/icons-material/Reddit";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Audiotrack } from "@mui/icons-material";
import NullCheck from "../../components/NullCheck";

const Links = ({ iconSize, iconColor, bgcolor, borderRadius, showTitle }) => {
  const data = useSelector((state) => state?.biz?.data);

  const SOCIAL = [
    {
      id: 1,
      icon: (
        <WebIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 2,
      icon: (
        <HomeIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 3,
      icon: (
        <PublicIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 4,
      icon: (
        <PermContactCalendarIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 5,
      icon: (
        <FacebookIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 6,
      icon: (
        <TwitterIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 7,
      icon: (
        <XIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 8,
      icon: (
        <YouTubeIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 9,
      icon: (
        <InstagramIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 10,
      icon: (
        <LinkedInIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 11,
      icon: (
        <RedditIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 12,
      icon: (
        <AppleIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 13,
      icon: (
        <AndroidIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 14,
      icon: (
        <WhatsAppIcon
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
    {
      id: 15,
      icon: (
        <Audiotrack
          color={iconColor ?? "primary"}
          sx={{ fontSize: iconSize ?? 30 }}
        />
      ),
    },
  ];

  return data?.links?.map((link, index) => {
    return (
      <Stack key={index} alignItems="center" justifyContent="center">
        <IconButton
          sx={{
            borderRadius: borderRadius ? borderRadius : 100,
            backgroundColor: bgcolor ? bgcolor : "secondary.main",
            "&:hover": {
              backgroundColor: bgcolor ? bgcolor : "secondary.main",
            },
          }}
          onClick={() => window.open(`${link?.url}`)}
        >
          {
            SOCIAL.find(
              (social) => parseInt(social?.id) === parseInt(link?.icon)
            )?.icon
          }
        </IconButton>

        <NullCheck valueForCheck={showTitle}>
          <Typography color="secondary">{link?.title}</Typography>
        </NullCheck>
      </Stack>
    );
  });
};
export default Links;
