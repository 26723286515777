import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import { celIsraeli, email } from "../../../utils/validations";

const ClientDetails = ({ errors, control }) => {
  return (
    <Stack spacing={2}>
      <TextInput
        width={{ xs: "100%", md: "auto" }}
        errors={errors}
        name="clientCallNumner"
        title="CLIENT_CALL_NUMNER"
        control={control}
      />
      <TextInput
        width={{ xs: "100%", md: "auto" }}
        required
        errors={errors}
        name="name"
        title="NAME"
        control={control}
      />
      <TextInput
        width={{ xs: "100%", md: "auto" }}
        required
        errors={errors}
        validation={celIsraeli}
        name="tel"
        title="TEL"
        control={control}
      />
      <TextInput
        width={{ xs: "100%", md: "auto" }}
        errors={errors}
        name="addr"
        title="ADDR"
        control={control}
      />
    </Stack>
  );
};
export default ClientDetails;
