import { Button, Stack, Typography } from "@mui/material";
import Header from "./Header";
import Description from "./Description";
import Links from "../components/Links";
import Map from "../../components/Map";
import CustomButton from "../../components/CustomButton";
import SignpostIcon from "@mui/icons-material/Signpost";
import Footer from "../../components/Footer";
import ToolsBar from "../components/ToolsBar";
import CsvButton from "../components/CsvButton";
import ServicesCall from "../components/servicesCall";
import ContactUs from "../components/ContactUs";
import { useSelector } from "react-redux";
import NullCheck from "../../components/NullCheck";
import VideoCarousel from "../components/VideoCarousel";
import Recommend from "../components/Recommend";

const WavesTemp = () => {
  const data = useSelector((state) => state?.biz?.data);
  const lat = data?.bizLatLonl ? data?.bizLatLonl?.split(",") : [];
  const position = lat.length > 0 ? [+lat[0], +lat[1]] : null;

  return (
    <Stack height="100%">
      <Stack
        spacing={0.5}
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
        height="100%"
      >
        <Header />

        {/* social media */}
        <Stack
          direction="row"
          justifyContent="center"
          padding={2}
          marginTop={0.5}
          gap={5}
          bgcolor="primary.main"
        >
          <Links borderRadius="10% 30%" iconSize={30} />
        </Stack>

        <Description />

        <NullCheck valueForCheck={data?.bizLatLonl}>
          <Stack
            justifyContent="space-between"
            gap={1}
            p={1}
            bgcolor="primary.main"
          >
            <Map 
              position={position} 
              height={150} 
              width="100%" 
              borderRadius="20% 20% 0% 0%"
            />

            <Stack direction="row" gap={1}>
              <CustomButton
                borderRadius="0% 0% 40% 0% "
                fullWidth
                fontBold
                height="100%"
                color="secondary"
                title="WAZE"
                icon={<SignpostIcon color="primary" />}
                onClick={() =>
                  window.open(
                    `https://waze.com/ul?ll=${data?.bizLatLonl}&navigate=yes`
                  )
                }
              />
              <CustomButton
                borderRadius="0% 0% 0% 40%"
                fullWidth
                height="100%"
                fontBold
                color="secondary"
                title="GOOGLE_MAP"
                icon={<SignpostIcon color="primary" />}
                onClick={() =>
                  window.open(`https://maps.google.com/?ll=${data?.bizLatLonl}`)
                }
              />
            </Stack>
          </Stack>
        </NullCheck>

        <VideoCarousel />

        <ServicesCall />

        <ContactUs />

        <Recommend />

        <Footer />
      </Stack>
      <ToolsBar />
    </Stack>
  );
};
export default WavesTemp;
