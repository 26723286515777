import { Fade, Stack } from "@mui/material";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { LANGUAGES_LIST } from "../config/const";

const Layout = ({ children }) => {
  const langType = useSelector((state) => state?.userSettings?.selectedLang);
  document.dir = LANGUAGES_LIST.find((lang) => lang.id === langType)?.dir;

  return (
    <Stack alignItems="center" height={{ xs: "100svh", md: "100vh" }}>
      <Fade in={true}>
        <Stack
          boxShadow={3}
          width="100vw"
          height="100%"
          maxWidth={500}
          bgcolor="bg.main"
        >
          {children}
          <Loading />
        </Stack>
      </Fade>
    </Stack>
  );
};
export default Layout;
