import { createSlice } from "@reduxjs/toolkit";

const smartQrSlice = createSlice({
  name: "smartQr",
  initialState: {
    data: null,
  },
  reducers: {
    setSmartQrData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setSmartQrData } = smartQrSlice.actions;
export default smartQrSlice.reducer;
