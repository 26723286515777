import "./App.css";
import "./i18n";
import { ThemeProvider, THEME_ID, createTheme } from "@mui/material/styles";
import { heIL } from "@mui/x-date-pickers/locales";
import { heIL as coreHeIL } from "@mui/material/locale";
import Routers from "./config/Routers";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function App() {
  const data = useSelector((state) => state?.biz?.data);
  let primaryColor = data?.color1 ?? "#1b263b";
  let secondaryColor = data?.color2 ?? "#FFFBDA";

  useEffect(() => {
    if (data?.bizName) {
      document.title = data?.bizName;
    }
  }, [data]);

  const theme = createTheme(
    {
      direction: "rtl",
      typography: {
        fontFamily: ["heebo", '"Segoe UI"', "Roboto"].join(","),
        h6: {
          fontSize: 17,
          fontWeight: 600,
        },
        h5: {
          fontSize: 21,
          fontWeight: 700,
        },
        h4: {
          fontSize: 24,
          fontWeight: 700,
        },
        h3: {
          fontSize: 28,
          fontWeight: 700,
        },
      },
      fonts: {
        body: "heebo,system-ui, sans-serif",
        heading: 'heebo, "Avenir Next", sans-serif',
        monospace: "heebo, Menlo, monospace",
      },
      components: {
        MuiButton: {
          defaultProps: { size: "small" },
        },
        MuiTextField: {
          defaultProps: { size: "small" },
        },
      },
      palette: {
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
        bg: {
          main: "#f6f7f9",
        },
      },
    },
    heIL,
    coreHeIL
  );

  return (
    <ThemeProvider theme={{ [THEME_ID]: theme }}>
      <StyleSheetManager stylisPlugins={[rtlPlugin]}>
        <Routers />
      </StyleSheetManager>
    </ThemeProvider>
  );
}

export default App;
