import axios from "axios";
import { useEffect } from "react";
import { apiUrl } from "../config/settings";
import { useDispatch, useSelector } from "react-redux";
import { LANGUAGES_LIST } from "../config/const";
import { setShowLoading } from "../store/sysSlice";
import { useNavigate, useParams } from "react-router-dom";
import { setBizData } from "../store/cardSlice";

const ApiProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bizPath, empPath } = useParams();

  const langType = useSelector((state) => state?.userSettings?.selectedLang);
  document.dir = LANGUAGES_LIST.find((lang) => lang.id === langType)?.dir;

  useEffect(() => {
    if (bizPath && empPath) {
      dispatch(setShowLoading(true));
      axios.get(`${apiUrl}/webApp/${bizPath}/${empPath}`)
        .then((response) => {
          if (response.status === 200) {
            axios.defaults.headers.common["BizId"] = `${response?.data?.bizId}`;
            dispatch(setBizData(response?.data));
          }
          dispatch(setShowLoading(false));
        })
        .catch((error) => {
          navigate("/");
          console.error("Auth error");
          dispatch(setShowLoading(false));
        });
    }
  }, [empPath, bizPath]);

  return children;
};

export default ApiProvider;
